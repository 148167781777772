import React, { Component } from "react"
import { TransitionPortal } from "gatsby-plugin-transition-link"
import { gsap } from "gsap"

const defaultState = {
  headerScrollEvents: true,
  toggleHeaderScrollEvents: () => {},
  disableHeaderScrollEvents: () => {},
  enableHeaderScrollEvents: () => {},
  transition: () => {},
}

export const { Provider, Consumer } = React.createContext(defaultState)

export function withGlobalContext(Component) {
  return function WrapperComponent(props) {
    return (
      <Consumer>{(state) => <Component {...props} context={state} />}</Consumer>
    )
  }
}

export class GlobalContextProvider extends Component {
  constructor(props) {
    super(props)

    this.state = {
      headerScrollEvents: true,
    }

    this.cover = React.createRef()
  }

  toggleHeaderScrollEvents = () => {
    this.setState({ headerScrollEvents: !this.state.headerScrollEvents })
  }

  disableHeaderScrollEvents = () => {
    if (this.state.headerScrollEvents === true) {
      this.setState({
        headerScrollEvents: false,
      })
    }
  }

  enableHeaderScrollEvents = () => {
    if (this.state.headerScrollEvents === false) {
      this.setState({
        headerScrollEvents: true,
      })
    }
  }

  transition = ({ node, props: { length: seconds }, direction }) => {
    const directionTo = direction === "up" ? "-100%" : "100%"
    const directionFrom = direction === "up" ? "100%" : "-100%"

    const wait = seconds / 6
    const half = (seconds - wait) / 2

    return gsap
      .timeline()
      .set(this.cover, { y: directionFrom })
      .to(this.cover, half, {
        y: "0%",
        ease: "power1.easeInOut",
      })
      .set(node, { opacity: 0 })
      .to(
        this.cover,
        half,
        {
          y: directionTo,
          ease: "power1.easeIn",
        },
        `+=${wait}`
      )
  }

  render() {
    const { children } = this.props
    const { headerScrollEvents } = this.state
    return (
      <Provider
        value={{
          headerScrollEvents,
          toggleHeaderScrollEvents: this.toggleHeaderScrollEvents,
          disableHeaderScrollEvents: this.disableHeaderScrollEvents,
          enableHeaderScrollEvents: this.enableHeaderScrollEvents,
          transition: this.transition,
        }}
      >
        {children}
        <TransitionPortal>
          <div
            ref={(n) => (this.cover = n)}
            style={{
              position: "fixed",
              background: "#161211",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              transform: "translateY(100%)",
            }}
          />
        </TransitionPortal>
      </Provider>
    )
  }
}
