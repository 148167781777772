import React from "react"
import "./static/fonts.css"
import "./src/assets/styles/base.scss"
import "aos/src/sass/aos.scss"
import "highlight.js/scss/github-gist.scss"
import "react-medium-image-zoom/dist/styles.css"
import "swiper/css"
import "swiper/css/pagination"

import { CookiesProvider } from "react-cookie"
import { GlobalContextProvider } from "components/context/GlobalContext"

export const wrapRootElement = ({ element }) => (
  <GlobalContextProvider>
    <CookiesProvider>{element}</CookiesProvider>
  </GlobalContextProvider>
)

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`)
  }
}

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  if (location.action === "POP") {
    window.scrollTo(0, 0)
  }
  return false
}
